import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Descriptions,
  Divider,
  Input,
  Select,
  Switch,
  Tabs,
  InputNumber,
  Button,
  Typography,
  Avatar,
  notification,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { businessAtom, profileAtom } from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function Options() {
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const [business, setBusiness] = useRecoilState(businessAtom);
  const [businessData, setBusinessData] = useState(null);
  const [businessOptions, setBusinessOptions] = useState(null);
  const [taxCredentials, setTaxCredentials] = useState(null);
  const [codePrefix, setCodePrefix] = useState('');
  const [cancellationFee, setCancellationFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingTaxCredentials, setLoadingTaxCredentials] = useState(false);
  const { Option, OptGroup } = Select;
  const { t } = useTranslation();

  const getTaxCredentials = function () {
    setLoadingTaxCredentials(true);
    api
      .get(`businesses/taxcredentials/${business.locations[0].id}`)
      .then((response) => {
        setLoadingTaxCredentials(false);
        setTaxCredentials(response.data.data);
      })
      .catch((error) => {
        setLoadingTaxCredentials(false);
        console.error(error);
      });
  };

  const getOptions = function () {
    setLoading(true);
    setBusinessData(business);
    getTaxCredentials();
    api
      .get(`businesses/options/${business.locations[0].id}`)
      .then((response) => {
        setLoading(false);
        setCancellationFee(response.data.data.cancellationFee);
        setCodePrefix(response.data.data.confirmationCodePrefix);
        setBusinessOptions(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (business) {
      getOptions();
    }
  }, [business]);

  useEffect(() => {
    if (business) {
      getOptions();
    }
  }, []);

  function updateBusinessOption(temp) {
    const toUpdate = temp;
    setLoading(true);
    api
      .post(
        'businesses/options/update',
        temp
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setBusinessOptions(toUpdate);
        } else {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function updateBusiness(temp) {
    const toUpdate = temp;
    setLoading(true);
    api
      .put(
        `businesses/${business.id}`,
        temp
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setBusiness(toUpdate);
        } else {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function updateBusinessLocation(temp) {
    const toUpdate = temp;
    setLoading(true);
    api
      .put(
        `businesses/${temp.locations[0].id}/location`,
        temp.locations[0]
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setBusiness(toUpdate);
        } else {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function updateTaxCredentials() {
    setLoadingTaxCredentials(true);
    api
      .post(`businesses/taxcredentials/${business.locations[0].id}`, taxCredentials)
      .then((response) => {
        setLoadingTaxCredentials(false);
      })
      .catch((error) => {
        setLoadingTaxCredentials(false);
        console.error(error);
      });
  }

  return (
    businessOptions && (
      <div>
        <Tabs>
          <Tabs.TabPane tab={t('store')} key="4">
            <div>
              <PageHeader title={t('dynamic_departments')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('dynamic_departments_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('sponsored_department')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessData?.locations[0]?.showSponsoredDepartment
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].showSponsoredDepartment = checked;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('offers_department')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessData?.locations[0]?.showOffersDepartment
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].showOffersDepartment = checked;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('recently_added_department')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessData?.locations[0]?.showRecentlyAddedDepartment
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].showRecentlyAddedDepartment = checked;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('healthy_department')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessData?.locations[0]?.showHealthyDepartment
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].showHealthyDepartment = checked;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('made_locally_department')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessData?.locations[0]?.showMadeLocallyDepartment
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].showMadeLocallyDepartment = checked;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div>
              <PageHeader title={t('search_filters')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('search_filter_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('default_filters')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.showDefaultSearchFilters
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.showDefaultSearchFilters = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('healthy_filters')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.showHealthySearchFilters
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.showHealthySearchFilters = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('brand_filters')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.showBrandSearchFilters
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.showBrandSearchFilters = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('custom_filters')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.showCustomSearchFilters
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.showCustomSearchFilters = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('notifications')} key="0">
            <div>
              <PageHeader title={t('notify_store')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('notify_store_by_email_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('send_alert')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessData?.locations[0]?.sendEmailNotifications
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].sendEmailNotifications = checked;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Divider />

              <PageHeader title={t('delay_in_orders')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('delay_in_orders_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('send_alert')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.sendDelayInOrdersAlert
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.sendDelayInOrdersAlert = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Divider />

              <PageHeader title={t('order_finished_packing')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('order_finished_packaging_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('send_alert')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.sendPickingFinishedAlert
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.sendPickingFinishedAlert = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <PageHeader title={t('order_ready_for_pickup')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('order_ready_for_pickup_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('send_alert')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.sendPackingFinishedAlert
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.sendPackingFinishedAlert = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('orders')} key="2">
            <div>
              <PageHeader title={t('confirmation_code_prefix')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('confirmation_code_prefix_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item>
                  <Input
                    loading={loading}
                    value={codePrefix}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.confirmationCodePrefix = obj.target.value;
                      setCodePrefix(obj.target.value);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Button
                loading={loading}
                type="primary"
                style={{
                  float: 'right',
                  margin: '10px 20px'
                }}
                onClick={() => {
                  const temp = JSON.parse(JSON.stringify(businessOptions));
                  temp.confirmationCodePrefix = codePrefix;
                  updateBusinessOption(temp);
                }}
              >
                {t('update_prefix')}
              </Button>
              <Divider />
            </div>
            <div>
              <PageHeader title={t('cancellation_fee')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('cancellation_fee_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label={t('type')}>
                  <Select
                    loading={loading}
                    listHeight={500}
                    style={{ width: 200 }}
                    defaultValue={businessOptions?.cancellationFeeType}
                    onChange={(value) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.cancellationFeeType = value;
                      updateBusinessOption(temp);
                    }}
                  >
                    <OptGroup
                      label={t('type')}
                      className="text-xs flex items-center"
                    >
                      <Option
                        key="0"
                        value={0}
                        className="text-xs flex items-center"
                      >
                        {t('none')}
                      </Option>
                      <Option
                        key="1"
                        value={1}
                        className="text-xs flex items-center"
                      >
                        {t('fixed')}
                      </Option>
                      <Option
                        key="2"
                        value={2}
                        className="text-xs flex items-center"
                      >
                        {t('percentage')}
                      </Option>
                    </OptGroup>
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item>
                  <InputNumber
                    disabled={businessOptions?.cancellationFee === 0 || loading}
                    placeholder="0.0"
                    loading={loading}
                    addonBefore="$"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    value={cancellationFee}
                    onChange={(obj) => {
                      setCancellationFee(obj);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Button
                type="primary"
                loading={loading}
                style={{
                  float: 'right',
                  margin: '10px 20px'
                }}
                onClick={() => updateBusiness(businessData)}
              >
                {t('update_cancellation_fee')}
              </Button>
              <Divider />
            </div>
            <div>
              <PageHeader title={t('order_acceptance')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('order_acceptance_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('allow_order_modification')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.allowOrderModification
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.allowOrderModification = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <Descriptions.Item label={t('charge_at_order_entry')}>
                  <Switch
                    loading={loading}
                    checked={businessOptions?.authAtOrderEntry}
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.authAtOrderEntry = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Divider />
            </div>
            <div>
              <PageHeader title={t('bin_management')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('bin_management_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('managed_bins_by_parent_store')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.useManagedStoreBins
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.useManagedStoreBins = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
                <br />
                <br />
                <br />
              </Descriptions>
              <Divider />
            </div>
            <PageHeader title={t('order_minimum_allowed')} />
            <div>
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('order_minimum_allowed_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('minimum_pickup_allowed')}>
                  <InputNumber
                    placeholder="0.0"
                    loading={loading}
                    disabled={loading}
                    addonBefore="$"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    value={businessData?.minimumPickupOrderAmount}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.minimumPickupOrderAmount = obj;
                      setBusinessData(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div>
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('minimum_pickup_allowed_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item label={t('minimum_delivery_amount')}>
                  <InputNumber
                    placeholder="0.0"
                    loading={loading}
                    disabled={loading}
                    addonBefore="$"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    value={businessData?.minimumDeliveryOrderAmount}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.minimumDeliveryOrderAmount = obj;
                      setBusinessData(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Button
                loading={loading}
                type="primary"
                style={{
                  float: 'right',
                  margin: '10px 20px'
                }}
                onClick={() => updateBusiness(businessData)}
              >
                {t('update_minimum')}
              </Button>
            </div>
            <Divider />
            <PageHeader title={t('additional_item_orders')} />
            <div>
              <Descriptions>
                <Descriptions.Item label={t('allow_additional_item_orders')}>
                  <Switch
                    loading={loading}
                    checked={
                      businessOptions?.allowAdditionalOrders
                    }
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(businessOptions));
                      temp.allowAdditionalOrders = checked;
                      updateBusinessOption(temp);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t('additional_order_item_limit')}>
                  <InputNumber
                    loading={loading}
                    placeholder="0"
                    value={businessData?.locations[0]?.additionalOrderItemsLimit}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(businessData));
                      temp.locations[0].additionalOrderItemsLimit = obj;
                      updateBusinessLocation(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
            <Divider />
            <PageHeader title={t('packing_accelerator')} />
            <Descriptions>
              <Descriptions.Item>
                <Typography.Paragraph>
                  {t('packing_accelerator_description')}
                </Typography.Paragraph>
              </Descriptions.Item>
              <br />
              <Descriptions.Item label={t('mark_picked_as_packed')}>
                <Switch
                  loading={loading}
                  checked={
                    businessOptions?.markPickedAsPacked
                  }
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.markPickedAsPacked = checked;
                    updateBusinessOption(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item>
                <Typography.Paragraph>
                  {t('mark_not_packed_as_out_description')}
                </Typography.Paragraph>
              </Descriptions.Item>
              <br />
              <Descriptions.Item label={t('mark_not_packed_as_out')}>
                <Switch
                  loading={loading}
                  checked={
                    businessOptions?.markNotPackedAsOut
                  }
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.markNotPackedAsOut = checked;
                    updateBusinessOption(temp);
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <PageHeader
              title={t('transfer_delays')}
              extra={[
                <Button
                  size="small"
                  type="primary"
                  disabled={!isAdmin}
                  loading={loading}
                  onClick={() => updateBusinessOption(businessOptions)}
                >
                  {t('update')}
                </Button>
              ]}
            />
            <Descriptions>
              <Descriptions.Item label={t('stripe_transfer_delays')}>
                <InputNumber
                  min="1"
                  disabled={!isAdmin}
                  value={businessOptions.stripeTransferDelay}
                  onChange={(val) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.stripeTransferDelay = val;
                    setBusinessOptions(temp);
                  }}
                />
              </Descriptions.Item>
              <br />
              <Descriptions.Item label={t('paypal_transfer_delays')}>
                <InputNumber
                  min="1"
                  disabled={!isAdmin}
                  value={businessOptions.payPalTransferDelay}
                  onChange={(val) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.payPalTransferDelay = val;
                    setBusinessOptions(temp);
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('products')} key="3">
            <PageHeader title={t('oos_trigger')} />
            <Descriptions>
              <Descriptions.Item>
                <Typography.Paragraph>
                  {t('oos_trigger_description')}
                </Typography.Paragraph>
              </Descriptions.Item>
              <br />
              <Descriptions.Item label={t('manage_oos')}>
                <Switch
                  loading={loading}
                  checked={businessOptions?.outOfStockTrigger}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.outOfStockTrigger = checked;
                    updateBusinessOption(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item>
                <Typography.Paragraph>
                  {t('manage_oos_description')}
                </Typography.Paragraph>
              </Descriptions.Item>
              <br />
              <Descriptions.Item label={t('oos_trigger_limit')}>
                <Select
                  loading={loading}
                  listHeight={500}
                  style={{ width: 200 }}
                  defaultValue={
                    businessOptions?.outOfStockCountRequirement
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.outOfStockCountRequirement = value;
                    updateBusinessOption(temp);
                  }}
                >
                  <OptGroup className="text-xs flex items-center">
                    {_.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (num) => (
                      <Option
                        key={num}
                        value={num}
                        className="text-xs flex items-center"
                      >
                        {num}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <PageHeader title={t('subtitute_option')} />
            <Descriptions>
              <Descriptions.Item>
                <Typography.Paragraph>
                  {t('substite_option_description')}
                </Typography.Paragraph>
              </Descriptions.Item>
              <br />
              <Descriptions.Item label={t('products_selected_by_default')}>
                <Switch
                  loading={loading}
                  checked={
                    businessOptions?.subsSelectedByDefault
                  }
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(businessOptions));
                    temp.subsSelectedByDefault = checked;
                    updateBusinessOption(temp);
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('ivu_crendentials')} key="5">
            <div>
              <PageHeader title={t('ivu_crendentials')} />
              <Descriptions>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('ivu_provider')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item>
                  <Select
                    loading={loadingTaxCredentials}
                    listHeight={500}
                    style={{ width: 200 }}
                    value={taxCredentials?.provider}
                    onChange={(value) => {
                      const temp = JSON.parse(JSON.stringify(taxCredentials));
                      temp.provider = value;
                      setTaxCredentials(temp);
                    }}
                  >
                    <Option
                      key="0"
                      value={0}
                      className="text-xs flex items-center"
                    >
                      {t('none')}
                    </Option>
                    <Option
                      key="0"
                      value={1}
                      className="text-xs flex items-center"
                    >
                      {t('provided_by_pos')}
                    </Option>
                    <Option
                      key="0"
                      value={3}
                      className="text-xs flex items-center"
                    >
                      Softek
                    </Option>
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('merchant_id_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item>
                  <Input
                    disabled={loadingTaxCredentials}
                    value={taxCredentials?.merchantId}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(taxCredentials));
                      temp.merchantId = obj.target.value;
                      setTaxCredentials(temp);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('terminal_id_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item>
                  <Input
                    disabled={loadingTaxCredentials}
                    value={taxCredentials?.terminalId}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(taxCredentials));
                      temp.terminalId = obj.target.value;
                      setTaxCredentials(temp);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item>
                  <Typography.Paragraph>
                    {t('terminal_password_description')}
                  </Typography.Paragraph>
                </Descriptions.Item>
                <br />
                <Descriptions.Item>
                  <Input
                    disabled={loadingTaxCredentials}
                    value={taxCredentials?.terminalPassword}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(taxCredentials));
                      temp.terminalPassword = obj.target.value;
                      setTaxCredentials(temp);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Button
                loading={loadingTaxCredentials}
                type="primary"
                style={{
                  float: 'right',
                  margin: '10px 20px'
                }}
                onClick={() => updateTaxCredentials()}
              >
                {t('update')}
              </Button>
              <Divider />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  );
}

export default Options;
