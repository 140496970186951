import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useJsonToCsv } from 'react-json-csv';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Avatar,
  notification,
  Tooltip,
  Button,
  Table,
  Divider,
  Dropdown,
  Popconfirm,
  Modal,
  Menu,
  Descriptions,
  Select,
  InputNumber,
  Typography
} from 'antd';
import { Comment } from '@ant-design/compatible';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faPencilAlt, faReply, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  selectedEventTimeSlotAtom,
  selectedEventForTimeSlotsAtom,
  timezoneAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { getEventBookingStatus, bookingStatus, formatPhoneNumber } from '../../../../utils/functions';
import envConfig from '../../../../../envConfig';

function ManageEventBooking() {
  const event = useRecoilValue(selectedEventForTimeSlotsAtom);
  const { t } = useTranslation();
  const { Option } = Select;
  const [timeSlot, setTimeSlot] = useRecoilState(selectedEventTimeSlotAtom);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showEditBooking, setShowEditBooking] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const [showRefund, setShowRefund] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);

  const [cancellationFeeAmount, setCancellationFeeAmount]
    = useState(envConfig.REACT_APP_BOOKING_CANCELLATION_FEE);
  const [selectedPurchaseForCancellation, setSelectedPurchaseForCancellation] = useState(null);
  const [selectedBookingForCancellation, setSelectedBookingForCancellation] = useState(null);

  const width = window.innerWidth;

  const participantsListFields = {
    firstName: t('first_name'),
    lastName: t('last_name'),
    phoneNumber: t('phone'),
    email: t('email'),
    status: t('status'),
    createdAt: t('created_on')
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getBookings() {
    setLoading(true);
    bookingApi
      .get(`events/timeSlots/${timeSlot?.id}/bookings`)
      .then((response) => {
        setLoading(false);
        setBookings(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
        console.error(error.message);
      });
  }

  function createBooking(user, createCharge) {
    setLoading(true);
    bookingApi
      .post('events/booking', {
        UserId: user.id,
        EventTimeSlotId: timeSlot.id,
        BypassPayment: !createCharge
      })
      .then((response) => {
        setLoading(false);
        getBookings();
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
        console.error(error.message);
      });
  }

  function removeBooking(r, amount) {
    setLoading(true);
    bookingApi
      .post(`events/booking/${r.id}`, { CancellationFee: amount })
      .then((response) => {
        setLoading(false);
        setSelectedBookingForCancellation(null);
        getBookings();
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
        console.error(error.message);
      });
  }

  function updateBooking() {
    setLoading(true);
    bookingApi
      .put('events/booking', {
        Status: selectedBooking.status,
        Comments: selectedBooking.comments,
        BookingId: selectedBooking.id
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          setSelectedBooking(null);
          setShowEditBooking(false);
          getBookings();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function searchUsers() {
    api
      .post('users/search', { SearchText: searchText })
      .then((response) => {
        if (response?.data?.success) {
          setUsers(response.data.data.results);
        } else {
          setLoading(false);
          showMessage(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function cancelPurchase(purchase, amount) {
    setLoading(true);
    bookingApi
      .post(`purchases/cancel/v2/${purchase.id}`, { CancellationFee: amount })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          setSelectedPurchaseForCancellation(null);
          getBookings();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function createPurchase(booking) {
    setLoading(true);
    bookingApi
      .post('purchases', {
        BookingId: booking.id,
        UserId: booking.userId,
        Amount: event.price
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          getBookings();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function refundPurchase() {
    setLoading(true);
    bookingApi
      .post('purchases/refund', {
        PurchaseId: selectedPurchase.id,
        RefundAmount: selectedPurchase.amountToRefund
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          setShowRefund(false);
          setSelectedBooking(null);
          setSelectedPurchase(null);
          getBookings();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (timeSlot) {
      getBookings();
    }
  }, [timeSlot]);

  return (
    <>
      <Drawer
        title={t('bookings')}
        placement="right"
        closable={!loading}
        onClose={() => setTimeSlot(null)}
        open={timeSlot}
        width={width < 1024 ? width : 575}
      >
        <div>
          <strong>{t('timeSlot')}</strong>: {' '}
          <Typography.Text>
            {moment(timeSlot?.startTime).add(-4, 'hour').format('dddd MMM D h:mm A')} - {moment(timeSlot?.endTime).add(-4, 'hour').format('dddd MMM D h:mm A')}
          </Typography.Text>
          <br />
          <br />
          <strong>{t('CreateNewBookingForUser')}</strong>
          <Dropdown
            trigger="click"
            placement="bottom"
            overlay={
              <Menu>
                {_.map(users, (u) => (
                  <Popconfirm
                    title={t('should_create_charge')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={() => {
                      createBooking(u, true);
                      setSearchText('');
                      setUsers([]);
                    }}
                    onCancel={() => {
                      createBooking(u, false);
                      setSearchText('');
                      setUsers([]);
                    }}
                  >
                    <Menu.Item
                      key={u.id}
                    >
                      {u.completeName}: {u.email}
                    </Menu.Item>
                  </Popconfirm>
                ))}
              </Menu>
            }
          >
            <Input.Search
              className="w-full"
              allowClear
              disabled={loading}
              value={searchText}
              onChange={(obj) => {
                setSearchText(obj.target.value);
              }}
              onSearch={() => searchUsers()}
              enterButton={t('search')}
            />
          </Dropdown>
        </div>
        <Divider />
        <div style={{ float: 'right' }}>
          <Button
            size="small"
            type="primary"
            className="right ml-auto"
            onClick={() => {
              getBookings();
            }}
          >
            {t('reload')}
          </Button>
          {' '}
          <Button
            size="small"
            type="primary"
            className="right ml-auto"
            onClick={() => {
              const data = _.map(bookings, (p) => ({
                createdAt: moment(`${p.createdAt}+0000`)
                  .tz(timezone)
                  .format('MM/DD/YYYY h:mm a'),
                firstName: p.firstName,
                lastName: p.lastName,
                status: getEventBookingStatus(p.status),
                phoneNumber: formatPhoneNumber(p.phoneNumber),
                email: p.email
              }));
              saveAsCsv({
                data,
                fields: participantsListFields,
                filename: 'Event Participants',
              });
            }}
          >
            {t('export_list')}
          </Button>
        </div>
        <br />
        <Table
          className="mt-2"
          pagination={false}
          size="small"
          bordered
          scroll={{ x: 500 }}
          rowKey="id"
          loading={loading}
          columns={[
            {
              title: t('name'),
              key: 'status',
              align: 'center',
              className: 'text-xs',
              render: (text, row) => (
                <span>{row.firstName} {row.lastName}</span>
              ),
            },
            {
              title: t('status'),
              key: 'status',
              align: 'center',
              dataIndex: 'status',
              className: 'text-xs',
              render: (row) => (
                <span>{t(getEventBookingStatus(row))}</span>
              ),
            },
            {
              title: t('created_on'),
              key: 'createdAt',
              align: 'center',
              dataIndex: 'createdAt',
              className: 'text-xs',
              width: 80,
              render: (row) => (
                <div className="flex flex-col">
                  <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                  <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                  <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                </div>
              ),
            },
            {
              title: '',
              key: 'edit',
              align: 'center',
              className: 'text-xs',
              render: (text, record) => (
                <>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setShowEditBooking(true);
                      setSelectedBooking(record);
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} className="text-xs" />
                  </Button>
                  {record.status !== 2 && (
                    <>
                      <Popconfirm
                        title={t('confirm_cancel_booking_prompt')}
                        okText={t('yes')}
                        onConfirm={() => {
                          if (record.purchases.length > 0 &&
                            _.sumBy(record.purchases, (p) => p.amountPaid) > 0) {
                            setCancellationFeeAmount(envConfig.REACT_APP_BOOKING_CANCELLATION_FEE);
                            setSelectedBookingForCancellation(record);
                          } else {
                            removeBooking(record, 0);
                          }
                        }}
                      >
                        <Button
                          size="small"
                          type="primary"
                          danger
                          className="ml-2"
                        >
                          <FontAwesomeIcon icon={faTrash} className="text-xs" />
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        title={t('confirm_create_purchase_prompt')}
                        okText={t('yes')}
                        onConfirm={() => createPurchase(record)}
                      >
                        <Button
                          size="small"
                          type="primary"
                          className="ml-2"
                        >
                          <FontAwesomeIcon icon={faDollarSign} className="text-xs" />
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </>
              ),
            },
          ]}
          dataSource={bookings}
          expandable={{
            expandRowByClick: false,
            rowExpandable: (record) => record,
            expandedRowRender: (record) => (
              <>
                <Descriptions size="small" bordered column={2}>
                  <DescriptionsItem label={t('email')} span={1}>
                    <span>{record?.email}</span>
                  </DescriptionsItem>
                  <DescriptionsItem label={t('phone')} span={1}>
                    <span>
                      <Button
                        disabled={width > 1024}
                        type="link"
                        style={{ color: 'black' }}
                        href={`sms:${record.phoneNumber}`}
                      >
                        {formatPhoneNumber(record.phoneNumber)}
                      </Button>
                    </span>
                  </DescriptionsItem>
                  <DescriptionsItem label={t('comments')} span={2}>
                    <span>{record?.comments}</span>
                  </DescriptionsItem>
                </Descriptions>
                {record.purchases?.length > 0 && (
                  <Table
                    size="small"
                    bordered
                    scroll={{ x: 500 }}
                    rowKey="id"
                    dataSource={record.purchases}
                    columns={[
                      {
                        title: t('created_on'),
                        key: 'createdAt',
                        align: 'center',
                        dataIndex: 'createdAt',
                        className: 'text-xs',
                        width: 80,
                        render: (row) => (
                          <div className="flex flex-col">
                            <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                            <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                            <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                          </div>
                        ),
                      },
                      {
                        title: t('amount_paid'),
                        key: 'amount',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <span>${row.amountPaid.toFixed(2)}/${row.amount.toFixed(2)}</span>
                        ),
                      },
                      {
                        title: t('refunded'),
                        key: 'refunded',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <span>${row.refundAmount.toFixed(2)}</span>
                        ),
                      },
                      {
                        title: t('cancelled'),
                        key: 'cancelled',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <span>{row.cancelled ? t('yes') : t('no')}</span>
                        ),
                      },
                      {
                        title: '',
                        key: 'edit',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <>
                            {!row.cancelled && (
                              <>
                                {row.amountPaid > 0 && (
                                  <Button
                                    size="small"
                                    type="primary"
                                    onClick={() => {
                                      setSelectedPurchase(row);
                                      setShowRefund(true);
                                      setSelectedBooking(record);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faReply} className="text-xs" />
                                  </Button>
                                )}
                                <Popconfirm
                                  title={t('confirm_cancel_purchase_prompt')}
                                  okText={t('yes')}
                                  cancelText={t('no')}
                                  onConfirm={() => {
                                    if (row.amountPaid > 0) {
                                      setCancellationFeeAmount(
                                        envConfig.REACT_APP_BOOKING_CANCELLATION_FEE
                                      );
                                      setSelectedPurchaseForCancellation(row);
                                    } else {
                                      cancelPurchase(row, 0);
                                    }
                                  }}
                                >
                                  <Button
                                    size="small"
                                    type="primary"
                                    danger
                                    className="ml-2"
                                  >
                                    <FontAwesomeIcon icon={faTrash} className="text-xs" />
                                  </Button>
                                </Popconfirm>
                              </>
                            )}
                          </>
                        ),
                      }
                    ]}
                  />
                )}
              </>
            )
          }}
        />
      </Drawer>
      <Modal
        title={t('edit_booking')}
        open={showEditBooking}
        onCancel={() => {
          setSelectedBooking(null);
          setShowEditBooking(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setSelectedBooking(null);
              setShowEditBooking(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => updateBooking()}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <>
          <Descriptions size="small" bordered column={1}>
            <DescriptionsItem label={t('name')} span={1}>
              <span>{selectedBooking?.firstName} {selectedBooking?.lastName}</span>
            </DescriptionsItem>
            <DescriptionsItem label={t('email')} span={1}>
              <span>{selectedBooking?.email}</span>
            </DescriptionsItem>
            <DescriptionsItem label={t('phone')} span={1}>
              <span>{selectedBooking?.phoneNumber}</span>
            </DescriptionsItem>
            <DescriptionsItem label={t('status')} span={1}>
              <Select
                className="w-full"
                listHeight={300}
                onChange={(value) => setSelectedBooking({
                  ...selectedBooking,
                  status: value
                })}
                value={selectedBooking?.status}
              >
                {_.map(bookingStatus, (l) => (
                  <Option
                    key={l.type}
                    value={l.type}
                    className="text-xs flex items-center"
                  >
                    {t(l.name)}
                  </Option>
                ))}
              </Select>
            </DescriptionsItem>
            <DescriptionsItem label={t('comments')} span={1}>
              <Input
                className="w-full"
                disabled={loading}
                onChange={(obj) => {
                  setSelectedBooking({
                    ...selectedBooking,
                    comments: obj.target.value
                  });
                }}
                value={selectedBooking?.comments}
              />
            </DescriptionsItem>
          </Descriptions>
        </>
      </Modal>
      {showRefund && (
        <Modal
          title={t('refund')}
          open={showRefund}
          onCancel={() => {
            setShowRefund(false);
            setSelectedBooking(null);
            setSelectedPurchase(null);
          }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              loading={loading}
              onClick={() => {
                setShowRefund(false);
                setSelectedBooking(null);
                setSelectedPurchase(null);
              }}
            >
              {t('close')}
            </Button>,
            <Popconfirm
              title={t('confirm_create_refund')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => refundPurchase()}
            >
              <Button
                key="ok"
                type="primary"
                loading={loading}
              >
                {t('submit')}
              </Button>
            </Popconfirm>
          ]}
        >
          <>
            <strong>{t('amount_to_refund')}</strong>
            <InputNumber
              disabled={loading}
              className="w-full"
              min={0}
              step="0.01"
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(obj) => {
                setSelectedPurchase({
                  ...selectedPurchase,
                  amountToRefund: obj
                });
              }}
              value={selectedPurchase?.amountToRefund ?? 0}
            />
          </>
        </Modal>
      )}
      {(selectedBookingForCancellation || selectedPurchaseForCancellation) && (
        <Modal
          title={t('cancellation_fee')}
          open={selectedBookingForCancellation || selectedPurchaseForCancellation}
          onCancel={() => {
            setSelectedPurchaseForCancellation(null);
            setSelectedBookingForCancellation(null);
          }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              loading={loading}
              onClick={() => {
                setSelectedPurchaseForCancellation(null);
                setSelectedBookingForCancellation(null);
              }}
            >
              {t('close')}
            </Button>,
            <Popconfirm
              title={t('confirm_cancellation_fee')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => {
                if (selectedPurchaseForCancellation) {
                  cancelPurchase(selectedPurchaseForCancellation, cancellationFeeAmount);
                } else {
                  removeBooking(selectedBookingForCancellation, cancellationFeeAmount);
                }
              }}
            >
              <Button
                key="ok"
                type="primary"
                loading={loading}
              >
                {t('submit')}
              </Button>
            </Popconfirm>
          ]}
        >
          <>
            <strong>{t('amount_for_cancellation_fee')}</strong>
            <InputNumber
              disabled={loading}
              className="w-full"
              min={0}
              step="0.01"
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(obj) => {
                setCancellationFeeAmount(obj);
              }}
              value={cancellationFeeAmount}
            />
          </>
        </Modal>
      )}
    </>
  );
}
export default ManageEventBooking;
