import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Upload,
  Avatar,
  notification,
  Tooltip,
  Button,
  Image,
  Select,
  InputNumber,
  Switch
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  editBookingEventAtom,
  selectedBookingEventAtom,
  reloadBookingEventAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { isEmpty, eventTypes, eventSubscriberVisibility } from '../../../../utils/functions';

function EditEvent() {
  const { t, i18n } = useTranslation();
  const { Dragger } = Upload;
  const { Option } = Select;
  const { TextArea } = Input;
  const setReload = useSetRecoilState(reloadBookingEventAtom);
  const selectedEvent = useRecoilValue(selectedBookingEventAtom);
  const [editEvent, setEditEvent] = useRecoilState(editBookingEventAtom);
  const [loading, setLoading] = useState(false);
  const [loadingHosts, setLoadingHosts] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [data, setData] = useState(null);
  const [hosts, setHosts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingServiceTypes, setLoadingServiceTypes] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  function getServiceTypes() {
    setLoadingServiceTypes(true);
    bookingApi
      .get('servicetypes/list')
      .then((response) => {
        setLoadingServiceTypes(false);
        setServiceTypes(response.data.data);
      })
      .catch((error) => {
        setLoadingServiceTypes(false);
        console.error(error);
      });
  }

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const formdata = new FormData();
    formdata.append('file', file);
    setIsSubmitting(true);
    const response = await api
      .post('utility/bookingevents/uploadfile', formdata);
    setIsSubmitting(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (selectedEvent.id) {
      bookingApi.post('events/update', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditEvent(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    } else {
      bookingApi.post('events/create', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditEvent(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function remove() {
    setLoading(true);
    bookingApi.delete(`events/${selectedEvent?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditEvent(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function getHosts() {
    setLoadingHosts(true);
    bookingApi
      .get('hosts')
      .then((response) => {
        setLoadingHosts(false);
        setHosts(response.data.data);
      })
      .catch((error) => {
        setLoadingHosts(false);
        console.error(error);
      });
  }

  function getLocations() {
    setLoadingLocations(true);
    bookingApi
      .get('locations')
      .then((response) => {
        setLoadingLocations(false);
        setLocations(response.data.data);
      })
      .catch((error) => {
        setLoadingLocations(false);
        console.error(error);
      });
  }

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        setData({
          ...data,
          imageUrl: url
        });
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedEvent && editEvent) {
      getLocations();
      getHosts();
      getServiceTypes();
      if (selectedEvent.id) {
        setData(selectedEvent);
      } else {
        setData({
          imageUrl: '',
          description: '',
          descriptionEs: '',
          name: '',
          nameEs: '',
          nameEn: '',
          price: 0,
          subscriberVisibility: 1,
          type: 0,
          hostId: '',
          locationId: '',
          isActive: true,
          informationalData: ''
        });
      }
    } else if (!editEvent) {
      setData(null);
    }
  }, [selectedEvent, editEvent]);

  return (
    <Drawer
      title={selectedEvent?.id ? t('edit_event') : t('create_event')}
      placement="right"
      closable={!loading}
      onClose={() => setEditEvent(false)}
      open={editEvent}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
        <br />
      </div>
      <div className="mb-4">
        <strong>{t('is_active')}</strong>
        <Switch
          disabled={loading}
          style={{ float: 'right' }}
          checked={data?.isActive}
          onChange={(checked) => {
            setData({
              ...data,
              isActive: checked
            });
          }}
        />
      </div>
      <div className="mb-4">
        <strong>{t('internal_name')}</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.name}
          onChange={(obj) => {
            setData({
              ...data,
              name: obj.target.value
            });
          }}
        />
      </div>
      <div className="mb-4">
        <strong>{t('name')} EN</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEn}
          onChange={(obj) => {
            setData({
              ...data,
              nameEn: obj.target.value
            });
          }}
        />
      </div>
      <div className="mb-4">
        <strong>{t('name')} ES</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEs}
          onChange={(obj) => {
            setData({
              ...data,
              nameEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} EN</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.description}
          onChange={(obj) => {
            setData({
              ...data,
              description: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} ES</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.descriptionEs}
          onChange={(obj) => {
            setData({
              ...data,
              descriptionEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('informational_data')}</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.informationalData}
          onChange={(obj) => {
            setData({
              ...data,
              informationalData: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('price')}</strong>
        <InputNumber
          disabled={loading}
          className="w-full"
          min={0}
          step="0.01"
          addonBefore="$"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(obj) => {
            setData({
              ...data,
              price: obj
            });
          }}
          value={data?.price}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('host')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          onChange={(value) => setData({
            ...data,
            hostId: value
          })}
          value={data?.hostId}
        >
          {_.map(hosts, (l) => (
            <Option
              key={l.id}
              value={l.id}
              className="text-xs flex items-center"
            >
              {l.firstName} {l.lastName}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('location')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          onChange={(value) => setData({
            ...data,
            locationId: value
          })}
          value={data?.locationId}
        >
          {_.map(locations, (l) => (
            <Option
              key={l.id}
              value={l.id}
              className="text-xs flex items-center"
            >
              {l.name}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('subscriber_visibility')}</strong>
        <Select
          className="w-full"
          listHeight={300}
          onChange={(value) => setData({
            ...data,
            subscriberVisibility: value
          })}
          value={data?.subscriberVisibility}
        >
          {_.map(eventSubscriberVisibility, (l) => (
            <Option
              key={l.type}
              value={l.type}
              className="text-xs flex items-center"
            >
              {t(l.name)}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('type')}</strong>
        <Select
          className="w-full"
          listHeight={300}
          onChange={(value) => setData({
            ...data,
            type: value
          })}
          value={data?.type}
        >
          {_.map(eventTypes, (l) => (
            <Option
              key={l.type}
              value={l.type}
              className="text-xs flex items-center"
            >
              {t(l.name)}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('service_type')}</strong>
        <Select
          className="w-full"
          listHeight={300}
          disabled={loadingServiceTypes}
          onChange={(value) => setData({
            ...data,
            serviceTypeId: value
          })}
          value={data?.serviceTypeId}
        >
          {_.map(serviceTypes, (l) => (
            <Option
              key={l.id}
              value={l.id}
              className="text-xs flex items-center"
            >
              {i18n.language === 'en' ? l?.nameEn : l?.nameEs}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('image')}</strong>
        <Input
          className="w-full"
          disabled={loading}
          loading={isSubmitting}
          value={data?.imageUrl}
          onChange={(obj) => {
            setData({
              ...data,
              imageUrl: obj.target.value
            });
          }}
        />
        <Dragger {...imageDraggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        {data?.imageUrl && (
          <Image
            alt="locationimage"
            width={120}
            src={data?.imageUrl}
            className="cursor-pointer"
          />
        )}
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={data?.name?.length <= 0 || isEmpty(data?.locationId) || isEmpty(data?.hostId)}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditEvent;
