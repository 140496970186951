import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Upload,
  Avatar,
  Select,
  notification,
  Tooltip,
  Switch,
  InputNumber,
  DatePicker,
  Button,
  Image
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useRecoilState, useSetRecoilState, } from 'recoil';
import {
  editSubscriptionAtom,
  selectedSubscriptionAtom,
  reloadSubscriptionsAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { isEmpty, mapSubscriptionRecurrence } from '../../../../utils/functions';
import { dateRangePresets } from '../../../../utils/utils';

function EditSubscription() {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { Dragger } = Upload;
  const { TextArea } = Input;
  const setReload = useSetRecoilState(reloadSubscriptionsAtom);
  const [selectedSubscription, setSelectedSubscription] = useRecoilState(selectedSubscriptionAtom);
  const [editSubscription, setEditSubscription] = useRecoilState(editSubscriptionAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [locations, setLocations] = useState([]);

  function getLocations() {
    setLoadingLocations(true);
    bookingApi
      .get('locations')
      .then((response) => {
        setLoadingLocations(false);
        setLocations(response.data.data);
      })
      .catch((error) => {
        setLoadingLocations(false);
        console.error(error);
      });
  }

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const formdata = new FormData();
    formdata.append('file', file);
    setIsSubmitting(true);
    const response = await api
      .post('utility/subscriptions/uploadfile', formdata);
    setIsSubmitting(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (!data.hasPromo) {
      data.promoStartDate = null;
      data.promoEndDate = null;
      data.promoPrice = null;
      data.DateExtensionType = 0;
      data.DateExtensionValue = 1;
    } else {
      data.promoStartDate = moment(data.promoStartDate.toString());
      data.promoEndDate = moment(data.promoEndDate.toString());
    }
    if (data.autoRenewalDiscountType === 1) {
      data.autoRenewalDiscountValue /= 100;
    }
    if (!data.hasLimit) {
      data.limit = null;
    }

    // console.log(data);
    if (selectedSubscription.id) {
      bookingApi.put('subscriptions', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditSubscription(false);
            setSelectedSubscription(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    } else {
      bookingApi.post('subscriptions', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditSubscription(false);
            setSelectedSubscription(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function remove() {
    setLoading(true);
    bookingApi.delete(`subscriptions/${selectedSubscription?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditSubscription(false);
          setSelectedSubscription(null);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        setData({
          ...data,
          imageUrl: url
        });
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedSubscription && editSubscription) {
      getLocations();
      if (selectedSubscription.id) {
        setData({
          ...selectedSubscription,
          locationId: selectedSubscription?.locationId ?? null,
          hasPromo: selectedSubscription?.promoStartDate && selectedSubscription?.promoEndDate,
          promoStartDate: selectedSubscription?.promoStartDate ?? moment().startOf('day'),
          promoEndDate: selectedSubscription?.promoEndDate ?? moment().endOf('day'),
        });
      }
    } else if (!editSubscription) {
      setData(null);
    }
  }, [selectedSubscription, editSubscription]);

  return (
    <Drawer
      title={selectedSubscription?.id ? t('edit_subscription') : t('create_subscription')}
      placement="right"
      closable={!loading}
      onClose={() => {
        setEditSubscription(false);
        setSelectedSubscription(null);
      }}
      open={editSubscription}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('is_active')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.isActive}
          onChange={(checked) => {
            setData({
              ...data,
              isActive: checked
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('ordering')}</strong>
        <InputNumber
          disabled={loading}
          className="w-full"
          min={0}
          step="1"
          onChange={(obj) => {
            setData({
              ...data,
              ordering: obj
            });
          }}
          value={data?.ordering}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('internal_name')}</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.internalName}
          onChange={(obj) => {
            setData({
              ...data,
              internalName: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} (EN)</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEn}
          onChange={(obj) => {
            setData({
              ...data,
              nameEn: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} (ES)</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEs}
          onChange={(obj) => {
            setData({
              ...data,
              nameEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} (EN)</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.descriptionEn}
          onChange={(obj) => {
            setData({
              ...data,
              descriptionEn: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} (ES)</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.descriptionEs}
          onChange={(obj) => {
            setData({
              ...data,
              descriptionEs: obj.target.value
            });
          }}
        />
      </div>

      <div
        className="mb-4"
      >
        <strong>{t('recurrence')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          onChange={(value) => setData({
            ...data,
            recurrence: value
          })}
          value={data?.recurrence}
        >
          <Option value={0} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(0))}</Option>
          <Option value={1} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(1))}</Option>
          <Option value={2} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(2))}</Option>
          <Option value={3} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(3))}</Option>
          <Option value={4} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(4))}</Option>
          <Option value={5} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(5))}</Option>
          <Option value={6} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(6))}</Option>
          <Option value={7} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(7))}</Option>
          <Option value={8} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(8))}</Option>
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('price')}</strong>
        <InputNumber
          disabled={loading}
          className="w-full"
          min={0}
          step="0.01"
          addonBefore="$"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(obj) => {
            setData({
              ...data,
              price: obj
            });
          }}
          value={data?.price}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('allow_renewal')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.allowRenewal}
          onChange={(checked) => {
            setData({
              ...data,
              allowRenewal: checked
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('has_limit')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.hasLimit}
          onChange={(checked) => {
            setData({
              ...data,
              hasLimit: checked
            });
          }}
        />
      </div>
      {data?.hasLimit && (
        <div
          className="mb-4"
        >
          <strong>{t('limit')}</strong>
          <InputNumber
            disabled={loading}
            className="w-full"
            step="1"
            min={1}
            onChange={(obj) => {
              setData({
                ...data,
                limit: obj
              });
            }}
            value={data?.limit}
          />
        </div>
      )}
      <div
        className="mb-4"
      >
        <strong>{t('has_promo')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.hasPromo}
          onChange={(checked) => {
            setData({
              ...data,
              hasPromo: checked
            });
          }}
        />
      </div>
      {data?.hasPromo && (
        <>
          <div>
            <strong>{t('dates')}:</strong>
            <RangePicker
              disabled={loading}
              allowClear={false}
              format="MMM, DD YYYY"
              className="w-full"
              presets={dateRangePresets(t)}
              value={[
                dayjs(data?.promoStartDate),
                dayjs(data?.promoEndDate)
              ]}
              onChange={(date, dateString) => {
                if (dateString[0].length > 0 && dateString[0].length > 0) {
                  setData({
                    ...data,
                    promoStartDate: dateString[0],
                    promoEndDate: dateString[1]
                  });
                }
              }}
            />
          </div>
          <div
            className="mb-4"
          >
            <strong>{t('promo_price')}</strong>
            <InputNumber
              disabled={loading}
              className="w-full"
              step="0.01"
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(obj) => {
                setData({
                  ...data,
                  promoPrice: obj
                });
              }}
              value={data?.promoPrice}
            />
          </div>
          <div
            className="mb-4"
          >
            <strong>{t('date_extension_type')}</strong>
            <Select
              listHeight={300}
              className="w-full"
              onChange={(value) => setData({
                ...data,
                dateExtensionType: value
              })}
              value={data?.dateExtensionType}
            >
              <Option value={0} className="text-xs flex items-center">{t('none')}</Option>
              <Option value={1} className="text-xs flex items-center">{t('days')}</Option>
              <Option value={2} className="text-xs flex items-center">{t('weeks')}</Option>
              <Option value={3} className="text-xs flex items-center">{t('months')}</Option>
              <Option value={4} className="text-xs flex items-center">{t('years')}</Option>
            </Select>
          </div>
          {data?.dateExtensionType > 0 && (
            <div
              className="mb-4"
            >
              <strong>{t('date_extension')}</strong>
              <InputNumber
                disabled={loading}
                className="w-full"
                step="1"
                min={1}
                onChange={(obj) => {
                  setData({
                    ...data,
                    dateExtensionValue: obj
                  });
                }}
                value={data?.dateExtensionValue}
              />
            </div>
          )}
        </>
      )}
      <div
        className="mb-4"
      >
        <strong>{t('has_auto_renewal_discount')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.hasAutoRenewalDiscount}
          onChange={(checked) => {
            setData({
              ...data,
              hasAutoRenewalDiscount: checked
            });
          }}
        />
      </div>
      {data?.hasAutoRenewalDiscount && (
        <>
          <div
            className="mb-4"
          >
            <strong>{t('auto_renewal_discount_type')}</strong>
            <Select
              listHeight={300}
              className="w-full"
              onChange={(value) => setData({
                ...data,
                autoRenewalDiscountType: value
              })}
              value={data?.autoRenewalDiscountType}
            >
              <Option value={0} className="text-xs flex items-center">{t('fixed')}</Option>
              <Option value={1} className="text-xs flex items-center">{t('percentage')}</Option>
            </Select>
          </div>
          <div
            className="mb-4"
          >
            <strong>{t('auto_renewal_discount_value')}</strong>
            <InputNumber
              disabled={loading}
              className="w-full"
              step="0.01"
              addonBefore={data?.autoRenewalDiscountType === 0 ? '$' : ''}
              addonAfter={data?.autoRenewalDiscountType === 1 ? '%' : ''}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(obj) => {
                setData({
                  ...data,
                  autoRenewalDiscountValue: obj
                });
              }}
              value={data?.autoRenewalDiscountValue}
            />
          </div>
        </>
      )}
      <div
        className="mb-4"
      >
        <strong>{t('location')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          disabled={loadingLocations}
          onChange={(value) => setData({
            ...data,
            locationId: value
          })}
          value={data?.locationId}
        >
          <Option id="any" value={null} className="text-xs flex items-center">{t('any')}</Option>
          {_.map(locations, (l) => (
            <Option key={l.id} value={l.id} className="text-xs flex items-center">{l.name}</Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('image')}</strong>
        <Input
          className="w-full"
          disabled={loading}
          loading={isSubmitting}
          value={data?.imageUrl}
          onChange={(obj) => {
            setData({
              ...data,
              imageUrl: obj.target.value
            });
          }}
        />
        <Dragger {...imageDraggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        {data?.imageUrl && (
          <Image
            alt="locationimage"
            width={120}
            src={data?.imageUrl}
            className="cursor-pointer"
          />
        )}
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={isEmpty(data?.internalName) && isEmpty(data?.nameEn) && isEmpty(data?.nameEs)}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditSubscription;
